// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';

const knownErrors = [
  'Redirect',
  'ChunkLoadError',
  "Can't find variable: BV",
  "Can't find variable: ttd_dom_ready",
  'ttd_dom_ready is not defined',
  'BV is not defined',
  'Firebase: Error (auth/internal-error).'
];

const sharedSentrySetup = () => {
  const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

  Sentry.init({
    dsn: SENTRY_DSN || 'https://4125bb9242ea4cc48c161457da41c266@o267347.ingest.sentry.io/5341422',
    // Note: if you want to override the automatic release value, do not set a
    // `release` value here - use the environment variable `SENTRY_RELEASE`, so
    // that it will also get attached to your source maps
    beforeSend(event, hint) {
      let returnedEvent = event;
      if (
        hint?.originalException?.message &&
        knownErrors.includes(hint.originalException.message)
      ) {
        console.log(
          `Handle ${hint.originalException.message} error on path ${event?.request?.url}`
        );
        returnedEvent = null;
      }
      return returnedEvent;
    },
    beforeBreadcrumb(breadcrumb) {
      return breadcrumb.category === 'console' &&
        (breadcrumb.level !== 'error' || breadcrumb.message.startsWith('Warning:'))
        ? null
        : breadcrumb;
    }
  });
};

export default sharedSentrySetup;
