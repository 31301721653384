/* eslint-disable no-param-reassign */
import axios from 'axios';
// import { getAuth } from 'firebase/auth';
import { firebaseApp, firebaseInit } from '../auth/initFirebase';

export default function initAxios() {
  axios.interceptors.request.use(async (config) => {
    await firebaseInit;
    if (firebaseApp) {
      try {
        const { getAuth } = await import('firebase/auth');
        const auth = getAuth();
        const user = auth.currentUser;
        if (user) {
          const token = await user.getIdToken();
          config.headers.Authorization = `Bearer ${token}`;
        }
        // config.headers['Access-Control-Allow-Origin'] = '*';
      } catch {
        // continue
      }
    }
    return config;
  });
}
