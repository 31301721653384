/* eslint-disable react/forbid-prop-types */
import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { usePathname } from 'next/navigation';
import Script from 'next/script';
import { useRouter } from 'next/router';
import initFirebase from '@ui/legacy/utils/auth/initFirebase';
import initAxios from '@ui/legacy/utils/http/initAxios';
import '@ui/new-site/styles/normalize.scss';
import '@ui/new-site/styles/screen.scss';
import '@ui/legacy/styles/global.scss';

// Init the Firebase app.
initFirebase();

// Init Axios
initAxios();

// This default export is required in a new `pages/_app.js` file.
function IfApp({ Component, pageProps }) {
  const router = useRouter();
  const scrollTimer = useRef(null);
  const anchorFindTimer = useRef(null);
  const anchorFindCount = useRef(null);
  const [isLiveEditor, setIsLiveEditor] = useState(true);

  const pathName = usePathname();

  useEffect(() => {
    // Remove the server-side injected CSS.
    const jssStyles = document.querySelector('#jss-server-side');
    if (jssStyles) {
      jssStyles.parentElement.removeChild(jssStyles);
    }
  }, []);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setIsLiveEditor(window.location.pathname === '/_contentful/live-editor');
    }
  }, []);

  useEffect(() => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'pageview',
      page: { url: pathName }
    });
  }, [pathName]);

  const clearTimers = () => {
    if (anchorFindTimer.current) {
      clearInterval(anchorFindTimer.current);
      anchorFindTimer.current = null;
    }

    if (scrollTimer.current) {
      clearInterval(scrollTimer.current);
      scrollTimer.current = null;
    }
  };

  useEffect(() => {
    const path = window.location.hash;
    if (!path || !path.includes('#')) {
      return;
    }

    anchorFindCount.current = 0;
    window.history.scrollRestoration = 'manual';

    anchorFindTimer.current = setInterval(() => {
      const id = path.replace('#', '');
      const el = window.document.getElementById(id);
      document.documentElement.style.scrollBehavior = 'auto';

      if (!el) {
        anchorFindCount.current++;
        if (anchorFindCount.current === 25 && anchorFindTimer.current) {
          clearInterval(anchorFindTimer.current);
          anchorFindTimer.current = null;
        }
        return;
      }

      const pageYOffset = -100; // Sticky navigation bar
      const offset = 100;

      const timer = setInterval(() => {
        const element = window.document.getElementById(id);
        const targetTop = element.offsetTop + pageYOffset;
        if (window.scrollY >= targetTop - 10) {
          clearInterval(scrollTimer.current);
          scrollTimer.current = null;
          document.documentElement.style.scrollBehavior = 'smooth';
        } else {
          window.scrollTo({
            top: Math.min(targetTop, window.scrollY + offset),
            left: 0
          });
        }
      }, 20);

      scrollTimer.current = timer;
      clearInterval(anchorFindTimer.current);
      anchorFindTimer.current = null;
    }, 200);

    return clearTimers;
  }, []);

  /* GDPR Compliance */
  const osanoConfig = (locale) => {
    switch (locale) {
      case 'gb-en':
        return '8e2dd9cf-92e0-470b-bc4b-e1cd33833747'; // prod
      // return '1f594561-36a1-49cd-9211-30247cc5d47f'; // stage
      default:
        return process.env.NEXT_PUBLIC_OSANO_CONFIG || '577ef360-469a-406b-85e9-f7f9395abaf5'; // prod
      // return '1f594561-36a1-49cd-9211-30247cc5d47f'; // stage
    }
  };

  const osanoScript = `https://cmp.osano.com/AzytiGSnWt3QoaRU/${osanoConfig(
    router.locale
  )}/osano.js?variant=three`;

  return (
    <>
      {!isLiveEditor && (
        <>
          {/* Google Consent Mode script */}
          <Script
            id="google-consent-mode"
            dangerouslySetInnerHTML={{
              __html: `
                window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('consent', 'default', {
                  'ad_storage': 'denied',
                  'analytics_storage': 'denied',
                  'ad_user_data': 'denied',
                  'ad_personalization': 'denied',
                  'personalization_storage': 'denied',
                  'functionality_storage': 'granted',
                  'security_storage': 'granted',
                  'wait_for_update': 500
                });
                gtag("set", "ads_data_redaction", true);
              `
            }}
          />
          {/* Later:
            import { osanoAllowedLocales } from '../utils/constants';
            {osanoAllowedLocales.includes(router.locale) && ( ADD LOCALE )}
          */}
          <Script src={osanoScript} />
          <Script
            id="gtm"
            dangerouslySetInnerHTML={{
              __html: `
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
              new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
              j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
              'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','${process.env.NEXT_PUBLIC_GTM_ID}');
            `
            }}
          />
          <Script
            id="anti-flicker"
            strategy="beforeInteractive"
            dangerouslySetInnerHTML={{
              __html: `
            (function (a, s, y, n, c, h, i, d, e) {
              // console.time("GTM:end");
              // console.log("GTM:start");
              s.className += ' ' + y
              h.start = 1 * new Date()
              h.end = i = function () {
                s.className = s.className.replace(RegExp(' ?' + y), '')
                // console.timeEnd("GTM:end");
              }
              ;(a[n] = a[n] || []).hide = h
              setTimeout(function () {
                i()
                h.end = null
              }, c)
              h.timeout = c
            })(window, document.documentElement, 'async-hide', 'dataLayer', ${
              process.env.NEXT_PUBLIC_ANTI_FLICKER_TIMEOUT || 500
            }, {
              '${process.env.NEXT_PUBLIC_GTM_ID}': true,
            })
          `
            }}
          />
        </>
      )}

      <Component {...pageProps} />
    </>
  );
}

IfApp.propTypes = {
  Component: PropTypes.func.isRequired,
  pageProps: PropTypes.object.isRequired
};

export default IfApp; // Pass the Redux store directly to withRedux
