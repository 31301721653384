// import { initializeApp } from 'firebase/app';

const config = {
  apiKey: process.env.NEXT_PUBLIC_FIREBASE_API_KEY,
  authDomain: process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.NEXT_PUBLIC_FIREBASE_DATABASE_URL,
  projectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID
};

// eslint-disable-next-line import/no-mutable-exports
export let firebaseApp;

async function initFirebase() {
  const { initializeApp } = await import('firebase/app');
  if (!firebaseApp) {
    firebaseApp = initializeApp(config);
  }
  return firebaseApp;
}
export const firebaseInit = initFirebase();
export default initFirebase;
